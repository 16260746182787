.musicOn{
     transform: translate(93vw,3vh);
              width: 2.5vw;
              max-height: 3vw;
              height: 3vw;
              cursor: pointer;
}
.musicOff{
    transform: translate(93vw,3vh);
    
              width: 3vw;
              max-height: 3vw;
              height: 3vw;
              cursor: pointer;
         
}

@media (max-width:600px) {
    .musicOn{
transform: translate(93vw,3vh);
              width: 3.5vw;
              max-height: 4vw;
              height: 4vw;
              cursor: pointer;
}
.musicOff{
    transform: translate(92vw,1vh);
              width: 4vw;
              max-height: 4vw;
              height: 4vw;
              cursor: pointer;
         
}
    
}
.floating {
  width: 0;
  height: 0;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0px, 4px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.spinner {
   position: absolute;
   z-index: 2;
   left: 50%;
   top: 50%;
   height:60px;
   width:60px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}