.cust-card {
     background-image: linear-gradient(to right, #a330b8, #158897);
}
.drop-down-color{
     max-width: fit-content;
     background-image: linear-gradient(to right, #a330b8, #158897); 
     /* min-height: fit-content;
     min-width: fit-content; */
     margin-bottom: 3vh;
     }
.drop-down-item-color{
     background-image: linear-gradient(to right, #a330b8, #158897); 
     color:white;

     }
     .dropdown-menu{
          padding: 0;
     }
     .card-group{
      margin: 2vmax;
          padding: 3vmin;
          display: flex;
          justify-content: space-around;
          /* gap: 2vmin; */
     }
.card-group > * +* {
     margin: 0 0 0 3vmin;
}
     .cust-card{
          width:20vw;
          min-height: fit-content;
     }
.profile-graph{
     height:40vh;
}
.safari-profile-page-root{
        margin-left: 5vw;
        margin-right: 5vw;
        display: flex;
        flex-direction: column;
        /* gap: "7vmin", */
      
}
.safari-profile-page-root > *+*{
     margin:7vmin 0 0 0;
}
@media (max-width:700px){
          .card-group{font-size:3vmin;
               
     }
     
     .cust-card{
          font-size: 2vmin;
          width:30vw;
          height:15vh;
          text-align: center;
          align-items: baseline;
          min-height: fit-content;
          /* min-height: fit-content;
          min-width: fit-content; */

     }
     .profile-graph{
          height:45vh;
     
     }
     /* .drop-down-color{
          height:7vh;
          width:25vw;
          display:flex;
          align-items: center;
          justify-content: center;
          align-content: center;
     } */
}
 @media (max-width:350px){
          .card-group{font-size:2vmin;
               padding:0;
               margin:0;
               margin-top:5vh;
               align-items: center;
     }
     .cust-card{
          font-size: 2.2vmin;
          align-items: center;
          width:30vw;
          height:16vh;
          text-align: center;
          min-height: fit-content;
          /* min-height: fit-content;
          min-width: fit-content; */

     }
}