.leaderboard-root{
    display:flex;
    width:100vw;
    height:93vh;
    /* background-color: #e7e5e4; */
    position: absolute;
  /* background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
   background: linear-gradient(to right, #a330b8, #158897); */
    flex-direction: column;

  align-items: center;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
}
.leaderboard-table-root{
    margin-top:2vh;
   width: 75vw;
   height: 75vh;
     /* color:#e7e5e4; */
}

th {
    /* height:10vh; */
    width:12.5vw;
    text-align: center;
    font-size:1.5vmax;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
    background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}
.leaderboard-options{
    margin-top: 3vh;
    text-align: center;
}
.leaderboard-options > * {
    font-size: 1.1vmax;
}
.leaderboard-options > * + *{
    
    margin:0 0 0 2vw;
}
.leaderboard-options > button {
    background: linear-gradient(to right, #8d359c, #208b99);
              color: white;
              width: fit-content;
              height: fit-content;
              
}
.leaderboard-options > span {
    background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}
.leaderboard-page-input{
    width: 2.5vw;
text-align: center;
background-color:#e7e5e4;
 border-image: linear-gradient(to right, #85079b, #158897);
       /* background-image: linear-gradient(to right, #a330b8, #158897);  */
  border-image-slice: 1;
  border-width: 0 0 0.5vmin 0;
  opacity: 1; /* Firefox */
  background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}
td{
    text-align: center;
    font-size: 1.1vmax;
    font-weight: 500;
    /* border: 2px solid blue; */
    font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
        background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}

td > * +* {
    margin: 0 0 0 5vmin;font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
}
.leaderboard-user-data {
    border: 1.5px solid #07099b; 
    
  
}
@media (max-width:900px)
{
    .leaderboard-table-root{
        width:93vw;
        margin-top:7vh;
    }
    .leaderboard-table-root{
        height:60vh;
    }
    th{
        font-size:1.7vmax;
    }
    td{
        font-size:1.5vmax;
    }
    .leaderboard-options > *{
        margin-top: 5vh;
        font-size: 1.7vmax;
    }
    .leaderboard-page-input{
        width: 7vw;
    }

}