.LoginPage {
  display: flex;
  flex-direction: column;
  height: 93vh;/*Since the navbar is of height 7vh*/
  /* overflow-y: hidden;  */
  /* background-color:#e7e5e4; */
  position: absolute;
}
.credentials{
  display:flex;
  flex-direction: column;
}
.sign-up-credentials{
display:flex;
flex-direction: column;
/* gap:3vh; */
padding-top:6vmin;

}
.sign-up-credentials > * + *{
  margin:3vh 0 0 0;
}
.SignupPage{
  position: absolute;
  width: 100vw;
  height:93vh;
   /* background-color:#e7e5e4; */
   /* background-image: linear-gradient(to right, #a330b8, #158897);  */
  display:flex;
  flex-direction: column;
  /* gap:5vh; */
  font-size: 4vh;
  justify-content: center;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
  align-items: center;
  align-self: center;
  padding-bottom: 7vh;
}
.SignupPage > * +*{
  margin:5vh 0 0 0 ;
}

.input-field{
   background-color:transparent;
 border-image: linear-gradient(to right, #85079b, #158897);
       /* background-image: linear-gradient(to right, #a330b8, #158897);  */
  border-image-slice: 1;
  border-width: 0 0 0.5vmin 0;
  
  font-size :3vh;
  opacity: 1; /* Firefox */
   background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
   -webkit-text-fill-color: purple; 
   /* transparent wasn't working on inputs greater than the size of input box so i have kept it purple for now */
 
   
}

/* just done for ios */
.password-field{-webkit-text-security: disc;
    -moz-webkit-text-security: disc;
    -moz-text-security: disc;}
.input-field:focus{
  outline:none;
  border-width: 0 0 0.8vmin 0;


}

.login-sign-up-heading{
  font-size :6vmin;
  opacity: 1; /* Firefox */
  background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}
.input-field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size :inherit;
  opacity: 1; /* Firefox */
  background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}


.input-field::-ms-input-placeholder { /* Microsoft Edge */
  font-size :inherit;
  
  background: linear-gradient(to right, #85079b, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}

.login-signup-button{
              margin-top: 5vh;
             background: linear-gradient(to right, #85079b, #158897);
              color: white
            
}
.login-signup-button:hover{
  text-decoration: underline;
  text-decoration-thickness: 0.3vmin;
}
@media (max-width:500px){
.SignupPage{
  font-size:7vw;

}
.sign-up-credentials{
  width:70vw;
}
.login-sign-up-heading{
  /* margin:0 0 5vw 5vw; */
  font-size:10vw;
  text-align: center;
 width:70vw;
}
.login-signup-button{
  margin-top: 8vh;
  height: 8vh;
}
.input-field::placeholder{
font-size:inherit;
}
.input-field:-ms-input-placeholder{
  font-size: inherit;
}

}