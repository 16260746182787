




.nav-custom-color-test {
  height:7vh;
font-size: 2.2vh;/*later on change this cuz if height of window is really less then it becomes a problem*/
min-height: fit-content;
display: flex;
align-items: center;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
 text-align: center;
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
}
   .safari-nav-bar > * + *{/*select element which have a sibling so that i can recreate gap property of flexbox */
      margin: 0 0 0 3vw;
}
 .nav-custom-color-test > * > * >*> *:active{
    color: white;
    font-size:larger;
} 
@media (min-width:701px){
.nav-custom-color-test > * > * >*> *:hover{
    color: white;font-size:larger;
    /* cuz hover doesnt work properly on mobile phones and using inherit in the below media query was giving odd results */
}

}
@media (max-width:700px){

  .nav-custom-color-test{
    font-size:3vw;
  }
  .nav-logo{
    font-size:4vw;
  }
}
@media (min-width:800px) and (max-height:600px){
  .nav-logo{
    font-size:4vh;
  }
}
.gradient-button-loop {
  /* background-image: linear-gradient(to right, #e040fb, #00bcd4); */
  /* color:#eeeeee; */
  border-radius: 105px;
  width: 9vh;
  height: 4vh;
  z-index: 1;
  transition: all 0.4s ease;
}
.gradient-button-loop:hover {
  -webkit-animation: gradient 3s ease infinite;
  -moz-animation: gradient 3s ease infinite;
  animation: gradient 3s ease infinite;
}
.logout-button:hover{
  cursor:pointer;
}
@media (max-width: 300px)
{
  .nav-logo{
    font-size:4vw;
  margin:0;
  padding:0;
  }
  /* 
.nav-custom-color-test{
font-size: 1vw;} */
}