  .root-home-page{
      flex-direction: column;
       
        align-items: center;
    position: absolute;
  }
  .root-home-page > * +*{
      margin: 10vh 0 0 0;
  }
  .shadowy-text{
 font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
  font-size: 7vmax;
  margin-top: 3vmin;
  
  
  text-align: center;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;

    color: #131313;
    /* background-color: #e7e5e4; */
    letter-spacing: .15em;
   
    /* text-shadow: 
      1px -1px 0 #767676, 
      -1px 2px 1px #737272, 
      -2px 4px 1px #767474, 
      -3px 6px 1px #787777, 
      -4px 8px 1px #7b7a7a, 
      -5px 10px 1px #7f7d7d, 
      -6px 12px 1px #828181, 
      -7px 14px 1px #868585, 
      -8px 16px 1px #8b8a89, 
      -9px 18px 1px #8f8e8d, 
      -10px 20px 1px #949392, 
      -11px 22px 1px #999897, 
      -12px 24px 1px #9e9c9c, 
      -13px 26px 1px #a3a1a1, 
      -14px 28px 1px #a8a6a6, 
      -15px 30px 1px #adabab, 
      -16px 32px 1px #b2b1b0, 
      -17px 34px 1px #b7b6b5, 
      -18px 36px 1px #bcbbba, 
      -19px 38px 1px #c1bfbf, 
      -20px 40px 1px #c6c4c4, 
      -21px 42px 1px #cbc9c8, 
      -22px 44px 1px #cfcdcd, 
      -23px 46px 1px #d4d2d1, 
      -24px 48px 1px #d8d6d5, 
      -25px 50px 1px #dbdad9, 
      -26px 52px 1px #dfdddc, 
      -27px 54px 1px #e2e0df, 
      -28px 56px 1px #e4e3e2; */
  }


.heading{
    font-size: 4vmin;
    font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
    display: inline;
}
.heading-on-login{    
    margin-top: 20vh;
    justify-content: center;
    align-self: center;
    text-align: center;
     margin-left: 7vw;
     margin-right:7vw;
    font-size: 5vmin;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
    display: inline-block;
     background: linear-gradient(to right, #a330b8, #158897);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
}
#home-page-button {
    color:#e7e5e4;
        min-width: fit-content;
        width:9vmax;
        height:9vmax;
         border-radius: 50vw;
          border: 0.1vw solid #4b1564;
        font-size: 1.2vmax;
        font-weight: 400;
     background-image: linear-gradient(to right, #a330b8, #158897); 
      box-shadow: 0 1rem 1.25rem 0 rgba(22, 75, 195, 0.5),
    0 -0.25rem 1.5rem rgb(99, 21, 136) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
}
#home-page-button:hover{

    /* font-size:1.3vmax; */
    text-decoration: underline;

}

.arrow{
    border: solid black; 
     border-width: 0 0.7vmin 0.7vmin 0; 
 display: inline-block;   
    padding: 4vmin;
    margin-right:4vmin; 
    
transform: rotate(-45deg);
-webkit-transform: rotate(-45deg);
}

.container-of-option{
    display:flex ;
    flex-direction: row ;
    align-items: center ;
    
}
@media (max-width: 950px){
  .root-home-page > * +*{
      margin: 10vw 0 0 0;
  }
  .heading-on-login{
      margin-top: 20vh;
  }
  .shadowy-text{
        margin-top: 3vh;
        margin-left:12vw;
        margin-right: 12vw;
        /* text-shadow: 
      0.5px -0.5px 0 #767676, 
      -0.5px 1px 0.5px #737272, 
      -1px 2px 0.5px #767474, 
      -1.5px 3px 0.5px #787777, 
      -2px 4px 0.5px #7b7a7a, 
      -2.5px 5px 0.5px #7f7d7d, 
      -3px 6px 0.5px #828181, 
      -3.5px 7px 0.5px #868585, 
      -4px 8px 0.5px #8b8a89, 
      -4.5px 9px 0.5px #8f8e8d, 
      -5px 10px 0.5px #949392, 
      -5.5px 11px 0.5px #999897, 
      -6px 12px 0.5px #9e9c9c, 
      -6.5px 13px 0.5px #a3a1a1, 
      -7px 14px 0.5px #a8a6a6, 
      -7.5px 15px 0.5px #adabab, 
      -8px 16px 0.5px #b2b1b0, 
      -8.5px 17px 0.5px #b7b6b5, 
      -9px 18px 0.5px #bcbbba, 
      -9.5px 19px 0.5px #c1bfbf, 
      -10px 20px 0.5px #c6c4c4, 
      -10.5px 21px 0.5px #cbc9c8, 
      -11px 22px 0.5px #cfcdcd, 
      -11.5px 23px 0.5px #d4d2d1, 
      -12px 24px 0.5px #d8d6d5, 
      -12.5px 25px 0.5px #dbdad9, 
      -13px 26px 0.5px #dfdddc, 
      -13.5px 27px 0.5px #e2e0df, 
      -14px 28px 0.5px #e4e3e2; */
    }
     .container-of-option{
        display:flex;
        flex-direction: column;
        
       
    }
    .arrow{
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
margin-bottom:6vmin;
margin-right:0;
padding:2.7vmin;

}

}
@media (max-width: 550px){
    .heading-on-login{
        margin-top: 20vh!important;
    }
  .root-home-page > * +*{
      margin: 13vw 0 0 0;
  }
    .heading{
        font-size: 3.5vmin;
    }
    .container-of-option{
        display:flex;
        flex-direction: column;
        
       
    }
    .arrow{
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
margin-bottom:6vmin;
margin-right:0;
padding:2.7vmin;

}
.music-attribution{
    
    font-size: 2.7vmin;

}
}
.music-attribution .attribution{
position:absolute;
z-index: 1;
    visibility: hidden;
    color: #0a0a0a;
    font-size: 1.5vmin;
    /* margin: 5vh; */
    bottom: 5vh;

}
.music-attribution:hover .attribution {
visibility: visible;
}
@media (max-width: 325px){
    
  .root-home-page > * +*{
      margin: 10vw 0 0 0;
  }
.heading{
    font-size: 5vmin;
}
.container-of-option > * + *{
    margin:7vmin 0 0 0;
}
.arrow{
    padding:4vmin;
}
.shadowy-text{
    font-size: 12vmin;
}
.music-attribution{
    font-size:3.8vmin;
}
}
