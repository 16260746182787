
#cust-button {
  cursor: pointer;
  border-radius: 5vmax;
  width: 7vmax;
  height: 7vmax;
  line-height: 1.75vmax;
  font-size: 1.5vmax;
  font-weight: 600;
  border: 0.1vw solid #4b1564;
  background-image: linear-gradient(-180deg, #ff89d6 0%, #1037e7 100%);
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
  box-shadow: 0 1rem 1.25rem 0 rgba(22, 75, 195, 0.5),
    0 -0.25rem 1.5rem rgb(99, 21, 136) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
}

#cust-button span {
  color: transparent;
  background-image: linear-gradient(0deg, #ee82da 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
}
#cust-button:hover span{
  
  color:#eeeeee;
  
}

.normal-char {
  font-size: 3vmin;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
  color: #008000;
  text-align: center;
}
.blinkk {
  font-size: 3vmin;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;
  color: #008000;
  text-align: center;
  animation: animate-blink 1.5s linear infinite;
}

@keyframes animate-blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
.congrats-message {
  padding: 2vmax 1vmax 2vmax 1vmax;
  width: 55vw;
  height: 9vw;
  border-radius: 5vmax;
  line-height: 3vmax;
  min-width: 40vh;
  min-height: 8vh;
  overflow: visible;
  font-weight: 600;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(-180deg, #ff89d6 0%, #1037e7 100%);
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
  box-shadow: 0 1rem 1.25rem 0 rgba(22, 75, 195, 0.5),
    0 -0.25rem 1.5rem rgb(99, 21, 136) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
}
.congrats-message span {
  font-size: 2.2vmax;
  align-self: center;
  color: transparent;
  background-image: linear-gradient(0deg, #ee82da 0%, #fefafd 100%);
padding:10vmin;
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
}
.typing-score {
  width: 13vmax;
  height: 13vmax;
  max-width: 27vw;
  max-height: 27vw;

  min-width: fit-content;
  min-height: fit-content;
  border-radius: 60vmax;
font-family: "Comic Sans MS", "Comic Sans","Marker Felt",sans-serif;

  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);

  box-shadow: 0 1rem 1.25rem 0 rgba(22, 75, 195, 0.5),
    0 -0.25rem 1.5rem rgb(99, 21, 136) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
}
.typing-score span {
  color: transparent;
  background-image: linear-gradient(0deg, #ee82da 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
}

.glow-og {
  position: absolute;
  top: 2vh;
  width: 12vw;
  height: 12vw;
  border-radius: 50vmax;
  box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f,
    inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff,
    0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
}
.input-typing:focus {
  border-color: #3812be;
  outline-offset: 0px;
  outline: none;
  background-color: transparent;
  color: #1037e7;
  font-weight: 200;
  min-zoom: 1;
}
.input-typing::placeholder {
  color: #1037e7;
  font-weight: 200;
}
.bg-img {
  position: absolute;
  background-color: #e71010;
  background-size: cover;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0px, 4px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
.safari-root-speed-test{
  position: absolute;
  margin-top: 10vmin;
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;

}
.safari-root-speed-test > * + *{
margin:10vh 0 0 0;
}
.safari-score-card-group>*+*{
  margin:0 0 0 5vw;
}
@media (max-width:1000px){
  .congrats-message {
  
  margin:5vmax;
  width: 75vmin;
  height: 12vh;
  min-height: fit-content;
  min-width: fit-content;
  border-radius: 5vmax;
  line-height: 3vmax;

  font-weight: 600;
}
  .congrats-message span {

  font-size: 1.9vmax;
  align-self: center;
  color: transparent;
  background-image: linear-gradient(0deg, #ee82da 0%, #fefafd 100%);

  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
}


}
@media (max-width:500px)
{
  .congrats-message{
height:19vh;
padding:0;

  }
  #cust-button {
 
  border-radius: 50vmax;
  width: 11vmax;
  height: 11vmax;
}

}
.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/* ​tried on firefox the below lines are working fines but the editor is still showing error  unless i put comments like i have there*/
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/* ​ */
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/* ​ */
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/* ​ */
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}